<template>
  <div
    id="news"
    v-scroll.self="onScroll"
    class="pt-56 vh-100 overflow-y-auto"
  >
    <v-container>
      <v-row class="px-1 pb-4">
        <v-col class="pt-0">
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            background-color="bg"
            color="primary"
            grow
          >
            <v-tab
              v-for="(item, index) in tabText"
              :key="index"
              class="subtitle-1"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>

        <!-- 內容 -->
        <v-col
          cols="12"
          class="pa-0"
        >
          <component
            :is="newContent.component"
            :news="newContent.props"
          >
            <!--  Bottom LOADING  -->
            <v-col
              v-show="scrollBottom.progress"
              cols="12"
              class="text-center pb-0"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </v-col>
          </component>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { timezone } from '@/utils/day'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'
import { resStatus } from '../../../utils/resUtils'

import {
  PUSH_NEWS_DATA,
  SET_MEMBER_NEWS,
} from '@/store/mutation-types'

import Announcement from './components/Announcement.vue'
import Inbox from './components/Inbox.vue'

export default {
  components: {
    Inbox,
    Announcement,
  },

  data: () => ({
    form: {
      timezone: '',
      page: 1,
    },

    memberNewsForm: {
      timezone: '',
      page: 1,
    },

    scrollBottom: {
      status: true,
      ing: false,
      progress: false,
    },

    tab: 0,
  }),

  computed: {
    ...mapState({
      news: state => state.news.data,
      memberMassage: state => state.news.message,
    }),

    tabText() {
      return [this.$t('global.announcement'), this.$t('global.inbox')]
    },

    newContent() {
      const isTab0 = this.tab === 0
      return {
        component: isTab0 ? 'Announcement' : 'Inbox',
        props: isTab0 ? this.news : this.memberMassage,
      }
    },

  },

  watch: {
    tab(newTab) {
      this.resetBtoomSetting()
      this.tab === 0 ? this.getNewsData() : this.getMemberNews()
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.news'))
    this.form.timezone = timezone()
    this.memberNewsForm.timezone = timezone()

    this.tab === 0 ? this.getNewsData() : this.getMemberNews()
  },

  methods: {
    ...mapMutations([
      PUSH_NEWS_DATA,
      SET_MEMBER_NEWS,
    ]),

    ...mapActions(['set_toolbar_title', 'get_news_api', 'show_alert', 'get_member_news', 'set_member_news']),

    getNewsDataSuccess(data) {
      const news = data.news.data
      if (news.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.form.page = this.form.page - 1
        this.scrollBottom.ing = false

        return this.show_alert({
          icon: 'fail',
        })
      }

      if (this.form.page > 1) {
        this.PUSH_NEWS_DATA(news)
        this.scrollBottom.progress = false
      }
    },

    getNewsDataFail() {},

    getMemberNewsSuccess({ message }) {
      const lastPage = message.last_page
      if (this.memberNewsForm.page === 1) this.SET_MEMBER_NEWS(message)

      if (message.data.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.memberNewsForm.page = this.memberNewsForm.page - 1
        this.scrollBottom.ing = false

        // TODO DEBUG
        // return this.show_alert({
        //   icon: 'fail',
        // })
        return false
      }

      if (this.memberNewsForm.page > 1) {
        this.set_member_news(message.data)
        this.scrollBottom.progress = false
      }
    },

    getMemberNewsFail() {},

    // 取得站內信
    async getMemberNews() {
      const res = await this.get_member_news(this.memberNewsForm)
      resStatus(res, this.getMemberNewsSuccess, this.getMemberNewsFail)
    },

    // 取得公告
    async getNewsData() {
      const res = await this.get_news_api(this.form)
      resStatus(res, this.getNewsDataSuccess, this.getNewsDataFail)
    },

    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.recordData = []
      this.tab === 0 ? this.form.page = 1 : this.memberNewsForm.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.tab === 0 ? this.form : this.memberNewsForm,
        scrollData: this.scrollBottom,
        func: () => this.tab === 0 ? this.getNewsData() : this.getMemberNews(),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (this.tab === 0 ? this.form.page === this.news.lastPage : this.memberNewsForm.page === this.memberMassage.lastPage) return false

      if (!isScrollToBottom(e)) {
        return false
      }
      if (
        isScrollToBottom(e) &&
        !this.scrollBottom.status &&
        this.scrollBottom.ing
      ) { return false }

      const bottom = this.setBottomFun()
      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.news {
	height: 96px;

	&__content {
		line-height: 16px;
	}
}
</style>
