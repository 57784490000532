<template>
  <div class="pt-56">
    <v-container>
      <v-row
        v-if="$route.query.t === 'mn' ? memberNewsItem : newsItem"
        class="px-1"
      >
        <v-col
          cols="12"
          class="news"
        >
          <div class="news__title subtitle-1 font-weight-medium">
            {{ $route.query.t === 'mn' ? memberNewsItem.title : newsItem.title }}
          </div>
          <div class="news__date text-right caption hint--text">
            {{ $route.query.t === 'mn' ? memberNewsItem.start_datetime : newsItem.start_datetime }}
          </div>
        </v-col>

        <!-- content -->
        <v-col
          cols="12"
          class="pt-0"
        >
          <div class="card1 pa-4 rounded caption font-weight-regular">
            {{ $route.query.t === 'mn' ? memberNewsItem.content : newsItem.content }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { timezone } from '@/utils/day'
import { resStatus } from '../../../utils/resUtils'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    form: {
      timezone: '',
      page: 1,
    },
  }),

  computed: {
    ...mapGetters(['filterNews', 'filterMember']),

    newsItem() {
      return this.filterNews(this.id)
    },
    memberNewsItem() {
      return this.filterMember(this.id)
    },
  },

  created() {
    // 每頁十筆 i => 第幾筆新聞
    this.form.page = Math.ceil(+this.$route.query.i / 10)
    this.form.timezone = timezone()
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.news'))
    this.getNewsData()
  },

  updated() {
    if (!this.newsItem) this.$router.replace({ path: '/user/news' })
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_news_api',
      'fetch_news_api_success',
    ]),

    getNewsDataSuccess(data) {
      if (this.form.page !== 1) this.fetch_news_api_success(data)
    },

    async getNewsData() {
      const res = await this.get_news_api(this.form)
      resStatus(res, this.getNewsDataSuccess)
    },
  },
}
</script>

<style lang="scss" scoped></style>
