<template>
  <div>
    <Nodata
      v-if="news.items.length === 0"
      :text="'No Message!'"
    />

    <template v-else>
      <v-col
        v-for="(item, index) in news.items"
        :key="item._id"
        cols="12"
        class="py-1"
      >
        <div
          class="text-decoration-none"
          @click="goMemberMessageItem({id: item._id, i: index + 1})"
        >
          <div
            class="news rounded title--text subtitle-2 pa-4"
            :class="[item.read === 'N' ? 'inputBg' : 'card1']"
          >
            <div class="news__header d-flex align-center">
              <Icon
                data="@icon/bell.svg"
                width="16px"
                height="16px"
                class="secondary--text mr-1"
              />
              <span
                class="news__header__title"
                :class="[item.read === 'N' ? 'icon--text' : 'hint--text']"
              >
                {{
                  item.title.length > 12
                    ? item.title.slice(0, 10) + '...'
                    : item.title
                }}
              </span>

              <v-spacer />

              <span
                class="news__header__time caption"
                :class="[item.read === 'N' ? 'icon--text' : 'hint--text']"
              >
                {{ item.start_datetime }}
              </span>
            </div>
            <div
              class="news__content caption mt-2"
              :class="[item.read === 'N' ? 'icon--text' : 'hint--text']"
            >
              {{
                item.content.length > 56
                  ? item.content.slice(0, 50) + '...'
                  : item.content
              }}
            </div>
          </div>
        </div>
      </v-col>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Nodata from '../../../../components/base/Nodata.vue'

export default {
  components: {
    Nodata,
  },

  props: {
    news: {
      required: true,
      type: Object,
    },
  },

  methods: {
    ...mapActions(['update_member_news_status']),

    goMemberMessageItem({ id, i }) {
      this.update_member_news_status(id)

      this.$router.push({
        name: 'news-item',
        params: {
          id: id,
        },
        query: {
          i: i,
          t: 'mn', // memberMessage
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
