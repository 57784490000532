<template>
  <div>
    <v-col
      v-for="(item, index) in news.items"
      :key="item._id"
      cols="12"
      class="py-1"
    >
      <router-link
        :to="{
          name: 'news-item',
          params: {
            id: item._id,
          },
          query: {
            i: index + 1,
          },
        }"
        class="text-decoration-none"
      >
        <div class="news card1 rounded title--text subtitle-2 pa-4">
          <div class="news__header d-flex align-center">
            <Icon
              data="@icon/bell.svg"
              width="16px"
              height="16px"
              class="secondary--text mr-1"
            />
            <span class="news__header__title">
              {{
                item.title.length > 12
                  ? item.title.slice(0, 10) + '...'
                  : item.title
              }}
            </span>

            <v-spacer />

            <span class="news__header__time caption  hint--text">
              {{ item.start_datetime }}
            </span>
          </div>
          <div class="news__content caption mt-2">
            {{
              item.content.length > 56
                ? item.content.slice(0, 50) + '...'
                : item.content
            }}
          </div>
        </div>
      </router-link>
    </v-col>
  </div>
</template>

<script>
export default {
  props: {
    news: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
